import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appActiveDay]'
})
export class ActiveDayDirective implements OnChanges {
  @Input() appActiveDay: Date;
  @Input() dates: { calStartDate: string, calEndDate: string }[];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(): void {
    this.updateActiveClass();
  }

  private updateActiveClass() {
    const day = this.appActiveDay;
    const isActive = this.dates.some(date => {
      const startDate = new Date(date.calStartDate);
      const endDate = new Date(date.calEndDate);
      return day >= startDate && day <= endDate;
    });

    if (isActive) {
      this.renderer.addClass(this.el.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'active');
    }
  }
}