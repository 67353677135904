export interface Currency {
  id?: string;
  name?: string;
  code?: string;
  symbol?: string;
  prefix?: boolean;
  decimalDigits?: string;
  digit?: string;
  negativeNumberBracket?: boolean;
}

export type CurrencyMap = { [key: string]: Currency };
