// DTO là data fields sẻ GỬI và NHẬN từ server.
// Vì vậy phải map field đúng 100% so với phía server.
// DTO chia làm 2 loại:
// 1. Không kèm từ Response -> gửi lên server
// 2. kèm từ Response -> fields data từ server
// LƯU Ý: DTO chỉ dành cho service, ko dành cho Repository, UI,

export interface ApproveUpdateDTO {
  createdAt?: Date;
  updatedAt?: Date;
  userId?: string;
  businessId?: string;
  isActive?: boolean;
  isBanned?: null;
  bannedCode?: null;
  displayName?: null;
  authorId?: null;
  role?: string;
  totalPoints?: number;
  lifetimePoints?: number;
  isFavorite?: null;
  allowNotification?: boolean;
  metadata?: null;
}

export interface RefuseUpdateDTO {
  all?: boolean;
  ids?: any[];
}

