// DTO là data fields sẻ GỬI và NHẬN từ server.
// Vì vậy phải map field đúng 100% so với phía server.
// DTO chia làm 2 loại:
// 1. Không kèm từ Response -> gửi lên server
// 2. kèm từ Response -> fields data từ server
// LƯU Ý: DTO chỉ dành cho service, ko dành cho Repository, UI,

export interface LoginDTO {
  username: string;
  password: string;
}

export interface LoginResponseDTO {
  accessToken: string;
  refreshToken: string;
  expires?: string;
  userId?: string;
}

export interface PhoneCheckDTO {
  phone: string;
}

export interface businessSignUpDTO {
  phone: string;
  name: string;
}

export interface businessSignUpVerifyDTO {
  phone: string;
  verifyId: string;
  name:string;
  code:string;
  timeZone: string;
}

export interface businessSignUpResendDTO {
  phone: string;
  verifyId: string;
  isVoice:boolean;
}


export interface RegisterDTO {
  email: string;
  phone?: string;
  password: string;
}

export interface ForgotPasswordDTO {
  email: string;
  phone?: string;
}
export interface RefreshTokenDTO {
  token: string;
}

export interface SetPassDTO {
  phone: string;
}

export interface SetPassIsValidDTO {
  phone: string;
  verifyId: string;
  code: string;
}

export interface SetPassResendDTO {
  phone: string;
  verifyId: string;
  isVoice?: boolean;
  method?: string;
  mValue?: string;
}

export interface SetPassVerifyDTO {
  phone: string;
  verifyId: string;
  code?: string;
  password?: string;
}

export interface ForgotPassDTO {
  code: string;
}

export interface ForgotPassIsValidDTO {
  identity: string;
  verifyId: string;
  code: string;
}

export interface ForgotPassResendDTO {
  identity: string;
  verifyId: string;
  isVoice?: boolean;
}

export interface ForgotPassVerifyDTO {
  id: string;
  code?: string;
  password?: string;
}
