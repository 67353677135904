/* eslint-disable @typescript-eslint/semi */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable eol-last */
/* eslint-disable @typescript-eslint/type-annotation-spacing */
import {
  AbstractControl,
  FormControl,
  ValidationErrors
} from '@angular/forms';

export class ValidatorHelper {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control?.value as string)?.trim().indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }
    return null;
  }

  static requiredText(control: FormControl) {
    let isValue = (control?.value || '')?.trim().length === 0;
    let isValid = !isValue;
    return isValid ? null : { requiredText: true };
  }

  // Validates Email
  static email(control: AbstractControl) {
    const EMAIL_REGEXP =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control.value === '') {
      return null;
    }
    if (!EMAIL_REGEXP.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }
}
