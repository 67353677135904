import { EnvironmentConfig } from './environment.type';

// Dev Local
export const environment: EnvironmentConfig = {
  production: false,
  name: 'Local Development',
  version: '0.0.1 (0002)',
  enableLogger: true,
  enablePWA: false,
  apiPath: 'https://dev-server.stickyqr.com',
  // apiPath: 'http://localhost:3000',
  apiBusinessPath: '',
  ablyKey: 'f1q7yg.f80baA:LKl1-gkcKFUQv5oTaohYpjWYDQFOukUHubsC2ILuIB4',
  stripeKey: 'pk_live_51NOu1RDQMUAIP6GQy8ERe4wylFpFgFL7mf5Y9uaBAozOflVetdfm5gIztGXR53JrXVeC5ubm4bT0fBHdZCYWLuBE00ARcXr5sw',
  cdn: 'https://cdnd.stickyqr.com/',
  sentry: undefined,
  stickyqrURL:'https://dev.stickyqr.com'
};
