// Models sẻ sử dụng cho Repository, UI
// KHÔNG sử dụng cho Service
export interface Business {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  name?: string;
  logo?: null;
  phone?: string;
  email?: null;
  timeZone?: string;
  currency?: string;
  type?: null;
  addressId?: string;
  slug?: null;
  userId?: string;
  authorId?: null;
  avatarId?: string;
  state?: string;
  currentPlan?: string;
  zipCode?: string;
  currentPlanInterval?: string;
  address?: Address;
  avatar?: Avatar;
  role?: string;
  username?: string;
  planMaxCustomers?: number;
  totalActiveCustomers?: number;
  enableReferralProgram?: boolean;
  currentAiCampaigns?: number;
  maxAiCampaignsPerDay?: number;
  credits?: number;
  acceptCustomerPayment?: boolean;
  acceptCustomerClaimsPoint?: boolean;
  enableSoundNewPointClaim?: boolean;
  soundNewPointClaimsRepeat?: number;
  soundNewPointClaimName?: number;
  referralProgram: {
    id?: string;
    createdAt?: Date;
    updatedAt?: Date;
    businessId?: string;
    pointsForReferrer?: number;
    pointsForReferredUser?: number;
    requiredPointsForCompletion?: number;
    metadata?: null;
  };
}

export interface Address {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  lat?: number;
  lng?: number;
  zipCode?: string;
}

export interface Avatar {
  id?: string;
  publicId?: string;
  file?: string;
  mimetype?: string;
  size?: number;
  format?: null;
  name?: string;
  version?: null;
  thumbnail?: null;
  businessId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  authorId?: null;
}
