// DTO là data fields sẻ GỬI và NHẬN từ server.
// Vì vậy phải map field đúng 100% so với phía server.
// DTO chia làm 2 loại:
// 1. Không kèm từ Response -> gửi lên server
// 2. kèm từ Response -> fields data từ server
// LƯU Ý: DTO chỉ dành cho service, ko dành cho Repository, UI,

export interface AuditDTO {
  uid?: string;
  environment?: string;
  os?: string;
  email?: string;
  phone?: string;
  address?: string;
  platform?: string;
  userAgent?: string;
  tz?: string;
  lang?: string;
  appVersion?: string;
  isLogged?: boolean;
  pageName?: string;
  pageUrl?: string;
  pageAction?: string;
  bid?: string;
  message?: string;
  metadata?: string;
}

export interface AuditUpdateDTO {
  uid?: string;
  environment?: string;
  os?: string;
  email?: string;
  phone?: string;
  address?: string;
  platform?: string;
  userAgent?: string;
  tz?: string;
  lang?: string;
  appVersion?: string;
  isLogged?: boolean;
  pageName?: string;
  pageUrl?: string;
  pageAction?: string;
  bid?: string;
  message?: string;
  metadata?: string;
}