/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import {
  parsePhoneNumberFromString,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js/min';

/**
 * Link thư viện: https://github.com/catamphetamine/libphonenumber-js
 */

@Injectable()
export class PhoneValidateHelper {
  constructor() {}

  /**
   * Cái này lấy ra được nhiều thông tin hơn
   */
  getInternationPhoneNumber(phone: any) {
    return parsePhoneNumberFromString(phone);
  }

  /**
   * Check nó có phải phiên bản quốc tế (có +XXX đầu)
   */
  isValidInternationPhoneNumber(phone: any) {
    return this.getInternationPhoneNumber(phone).isValid();
  }

  formatPhoneNumber(phone: any) {
    return parseIncompletePhoneNumber(phone);
  }
}
