// Models sẻ sử dụng cho Repository, UI

import { Business } from "../business";
import { User } from "../users";

// KHÔNG sử dụng cho Service
export interface StickyQRApp {
  currentUser?: User;
  currentUserId?: string;
  displayName?: string;
  role?: string;
  phone?: string;
  email?: string;
  business?: Business;
  currentBusinessId?: string;
}
