// DTO là data fields sẻ GỬI và NHẬN từ server.
// Vì vậy phải map field đúng 100% so với phía server.
// DTO chia làm 2 loại:
// 1. Không kèm từ Response -> gửi lên server
// 2. kèm từ Response -> fields data từ server
// LƯU Ý: DTO chỉ dành cho service, ko dành cho Repository, UI,

export interface BusinessUpdateDTO {
  name?: string;
  currency?: string;
  timeZone?: string;
  street?: AddressUpdateDTO;
  city?: string;
  state?: string;
  country?: string;
  lat?: number;
  lng?: number;
  avatarId?: string;
  zipCode?: string;
  avatar?: AvatarUpdateDTO;
  language?: string;
  phone?: string;
  acceptCustomerPayment?: boolean;
  acceptCustomerClaimsPoint?: boolean;
  enableSoundNewPointClaim?: boolean;
  soundNewPointClaimsRepeat?: number;
  soundNewPointClaimName?: number;
}

export interface AddressUpdateDTO {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  lat?: number;
  lng?: number;
  zipCode?: string;
}

export interface AvatarUpdateDTO {
  id?: string;
  publicId?: string;
  file?: string;
  mimetype?: string;
  size?: number;
  format?: null;
  name?: string;
  version?: null;
  thumbnail?: null;
  businessId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  authorId?: null;
}
export interface ReferralProgramUpdateDTO {
  isEnable?: boolean;
  pointsForReferrer?: number;
  pointsForReferredUser?: number;
  requiredPointsForCompletion?: number;
}
