import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appMultipleDatepicker]'
})
export class MultipleDatepickerDirective {
  @Output() selectedDatesChange = new EventEmitter<Date[]>();
  selectedDates: Date[] = [];

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('mat-calendar-body-cell-content')) {
      const date = new Date(target.getAttribute('aria-label') || '');
      const index = this.selectedDates.findIndex(d => d.getTime() === date.getTime());
      if (index >= 0) {
        this.selectedDates.splice(index, 1);
        target.classList.remove('selected');
      } else {
        this.selectedDates.push(date);
        target.classList.add('selected');
      }
      this.selectedDatesChange.emit(this.selectedDates);
    }
  }
}