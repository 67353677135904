import { Injectable } from '@angular/core'
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { AuthRepository } from '@sticky/state/auth'
import { canDo } from '@sticky/util-common'
import { AppRepository } from '@sticky/state/app'

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private _authRepo: AuthRepository,
    private _appRepository: AppRepository,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._authRepo.isLoggedIn$.pipe(
      map(yes => {
        if (!yes) {
          this.router.navigate(['/get-started'], {
            queryParams: { redirect: state.url },
            replaceUrl: true,
          })
          return yes
        }
        const role = this._appRepository?.role;
        // check permision for offer page
        const isAllow = canDo('OFFER', ['VIEW']);
        if (!isAllow) {
          this.router.navigate(['/dashboard'])
          return false
        }
        return true
      })
    )
  }
}
