// Models sẻ sử dụng cho Repository, UI


// KHÔNG sử dụng cho Service
export interface Approve {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  userId?: string;
  businessId?: string;
  points?: number;
  pendingPoints?: string;
  qrId?: null;
  type?: string;
  authorId?: string;
  phone?: string;
  email?: string;
  displayName?: string;
  firstName?: null;
  lastName?: null;
  metadata?: null;
}
export interface CustomerMeta {
  filters?: CustomerFilter;
}

export interface CustomerFilter {
  text?: string;
  firstName?: string;
  lastName?: string;
}
