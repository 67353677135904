// Models sẻ sử dụng cho Repository, UI

import { Business } from '../business';

// KHÔNG sử dụng cho Service
export interface AuthLogin {
  accessToken?: string;
  expiresIn?: Date;
  refreshToken?: string;
  userId?: string;
  displayName?: string;
  firstName?: null;
  isSupported?: boolean
  lastName?: null;
  phone?: string;
  email?: string;
  id?: string;
  role?: string;
  supportRole?: string;
  business?: Business;
  isSessionTimeout?: boolean;
}

export interface AuthPhoneCheck {
  id?: string;
  isSetPassword?: boolean;
  businessName?: string;
  businessPhone?: string;
  businessLogo?: string;
  email?: null;
}

export interface AuthBusinessSignUp {
  id?: string;
}
