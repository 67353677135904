// Models sẻ sử dụng cho Repository, UI
// KHÔNG sử dụng cho Service
export interface Audit {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  businessId?: string;
  isActive?: boolean;
  isBanned?: boolean;
  bannedCode?: null;
  displayName?: any;
  authorId?: string;
  role?: string;
  totalPoints?: number;
  lifetimePoints?: number;
  isFavorite?: null;
  allowNotification?: boolean;
  metadata?: null;
}
