import { HttpHeaders, HttpParams } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

export interface DataAccessConfig {
  baseURL: string;
  logging?: {
    enable: boolean;
    api: boolean;
  };
  ablyKey?: string;
}

export type ApiOptions = {
  params?: HttpParams;
  headers?: HttpHeaders;
  body?: unknown;
  responseType?: 'json' | 'text' | 'blob' | 'arraybuffer';
};

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DEL' | 'PATCH';

export interface CommonPagingRepsonse {
  count?: number;
  data?: unknown;
  hasMore?: boolean;
  page?: number;
  take?: number;
}

export enum Permission {
  ALL = 0,
  READ = 1,
  CREATE = 2,
  UPDATE = 3,
  DELETE = 4,
}
