// Models sẻ sử dụng cho Repository, UI
// KHÔNG sử dụng cho Service
export interface BusinessSA {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  name?: string;
  displayName?: string;
  logo?: string;
  phone?: string;
  email?: string;
  timeZone?: string;
  currency?: string;
  type?: null;
  addressId?: string;
  slug?: null;
  userId?: string;
  authorId?: string;
  mediaId?: string;
  language?: string;
  enableReferralProgram?: boolean;
  user?:BusinessSAUsers;
  address?: BusinessAddress
  avatarId?: string;
  dashboardSA:DashboardSupport
}

export interface BusinessSAUsers {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  username?: null;
  email?: string;
  emailVerified?: null;
  phone?: string;
  phoneVerified?: string;
  passwordHash?: string;
  avatarId?: string;
  isActive?: boolean;
  inActiveDate?: null;
  organizationId?: null;
  role?: string;
  totalPoints?: number;
  lifetimePoints?: number;
  slug?: null;
  globalNotification?: boolean;
  metadata?: null;
  language?: null;
}


export interface BusinessAddress {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  lat?: number;
  lng?: number;
  zipCode?: string;
}


export interface BusinessMeta{
  filters?: BusinessFilter;
}

export interface BusinessFilter{
  text?: string;
  displayName?: string;
}

export interface DashboardSupport {
  given?: number;
  business_new_return?: string;
  scanned_codes?: number;
  scanned_codes_month?: number;
  rewards_redeemed?: number;
  no_customers?: number;
  business_stats?: string;
  business_stats_referral?: string;
  top_rewards?: string[];
  top_customers_points?: string[];
  business_charts?: string;
  codesScanned?: any[];
  rewardsRedeemed?: any[];
  newCustomers?: any[];
  returningCustomers?: any[];
  code_rewards?: any;
  busId?: string;
  new_return_guest?: any;
  business_selected?: any;
  isBusinessPage?: boolean;
}
export interface Chart {
  codesScanned?: any[];
  newCustomers?: any[];
  returningCustomers?: any[];
  rewardsRedeemed?: any[];
}