// DTO là data fields sẻ GỬI và NHẬN từ server.
// Vì vậy phải map field đúng 100% so với phía server.
// DTO chia làm 2 loại:
// 1. Không kèm từ Response -> gửi lên server
// 2. kèm từ Response -> fields data từ server
// LƯU Ý: DTO chỉ dành cho service, ko dành cho Repository, UI,

export interface BusinessSADTO {
  phone?: string;
  ownerName?: string;
  name?: string;
  timeZone?: string;
  ownerPassword?: string;
  currency?: string;
  email?: string;
  mediaId?: string;
  language?: string;
  addrStreet?: string;
  addrCity?: string;
  addrState?: string;
  addrCountry?: string;
  addrZip?: string;
  addrLat?: number;
  addrLng?: number;
  ownerPhone?: string;
}

export interface BusinessSAAddressDTO {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  lat?: number;
  lng?: number;
  zipCode?: string;
}

export interface BusinessSAAvatarDTO {
  id?: string;
  publicId?: string;
  file?: string;
  mimetype?: string;
  size?: number;
  format?: null;
  name?: string;
  version?: null;
  thumbnail?: null;
  businessId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  authorId?: null;
}
